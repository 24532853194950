class Flag {

  constructor(flag) {
    this.id = flag.id
    this.accuser = flag.accuser
    this.createdAt = flag.createdAt
    this.updatedAt = flag.updatedAt
  }

  static sortByDate = (x,y) => {
    if (x.createdAt < y.createdAt) return 1
    if (x.createdAt > y.createdAt) return -1
    return 0
  } 

  toJson = () => {
    return {
      id: this.id,
      accuser: this.accuser,
    }
  }

  getData = () => {
    return this.id + this.accuser + this.createdAt + this.updatedAt
  }
}

export default Flag