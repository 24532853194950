import { createContext, useState } from 'react'
import { API } from 'aws-amplify'

import * as Query from '../api/queries'

import { useAlert } from '../hooks'
import { Flag } from '../classes'
import { Constant } from '../utils'

export const FlagContext = createContext({ 
  flags: null,
  listFlags: () => {},
  setFlags: () => {}
})

export const FlagProvider = (props) => {

  const { handleError } = useAlert()
  
  const [ flags, setFlags ] = useState(null)
  const [ timeout, setTimeout ] = useState(Date.now())

  const listFlags = async () => {

    if (timeout > Date.now()) { return }

    setTimeout(Date.now() + Constant.reloadFreq)
    console.log('API: listing flags')

    var flags = []
    var nextToken = null
    var maxIter = 100

    try {
      do {
        console.log('Fetching flags...')
        const result = await API.graphql({ 
          query: Query.listFlags, 
          variables: { limit: 300, nextToken: nextToken } 
        })
        flags = flags.concat(result.data.listFlags.items.map(obj => new Flag(obj)))
        nextToken = result.data.listFlags.nextToken
        console.log('Successfully fetched flags: ', flags.length)
        maxIter = maxIter - 1
      } while (nextToken != null && maxIter > 0)
    }
    catch (error) {
      handleError(error)
    }

    setFlags(flags.sort((x,y) => Flag.sortByDate(x,y)))
  }

  const value = { 
    flags,
    listFlags,
    setFlags
  }

  return (
    <FlagContext.Provider value={value}>
      { props.children }
    </FlagContext.Provider>
  )
}