import PropTypes from 'prop-types'
import Styled from 'styled-components'

const StyledDateInput = Styled.input`
  gap: var(--spc-s);
  padding-inline: var(--pdg-control-l);
  height: var(--siz-control-l);
  box-shadow: var(--sdw-component);
  border-radius: var(--rds-control);
  background: var(--clr-component);
` 

const DateInput = (props) => {

  return (
    <StyledDateInput 
      type='date' 
      placeholder={props.placeholder} 
      id={props.id} 
      onChange={props.onChange}
    >
    </StyledDateInput>
  ) 
}

export default DateInput