class Club {

  constructor(club) {
    this.id = club.id
    this.createdAt = club.createdAt
    this.updatedAt = club.updatedAt
    this.owner = club.owner
    this.ownerSystem = /[A-Z]/.test(club.id) ? "iOS" : "android"
    this.admins = club.admins
    this.cashiers = club.cashiers
    this.readers = club.readers
    this.reporters = club.reporters
    this.premiumAboState = club.premiumAboState
    this.isPremiumTill = club.isPremiumTill
    this.maxMemberCount = club.maxMemberCount
    this.numMembers = club.Members ? club.Members.items.length : 0
    this.numReports = club.Reports ? club.Reports.items.length : 0
    this.numTransactions = club.Transactions ? club.Transactions.items.length : 0
    this.name = club.info?.name ?? ""
  }

  static sortByDate = (x,y) => {
    if (x.createdAt < y.createdAt) return 1
    if (x.createdAt > y.createdAt) return -1
    return 0
  }

  static sortByName = (x,y) => {
    if (x.name > y.name) return 1
    if (x.name < y.name) return -1
    return 0
  } 

  toJson = () => {
    return {
      id: this.id,
      owner: this.owner,
      admins: this.admins,
      cashiers: this.cashiers,
      readers: this.readers,
      reporters: this.reporters,
    }
  }

  getData = () => {
    return this.id + this.createdAt + this.owner + this.isPremiumTill + this.name
  }

  connectedMembers = () => {
    return this.admins.length + this.cashiers.length + this.readers.length + this.reporters.length
  }

  isAndroid = () => {
    return this.ownerSystem == "android"
  }

  isIOS = () => {
    return this.ownerSystem == "iOS"
  }

  isConnected = () => {
    return this.admins.length + this.cashiers.length + this.reporters.length + this.readers.length > 0
  }

  createdBetween = (minDate, maxDate) => {
    return minDate.toISOString() < this.createdAt && this.createdAt < maxDate.toISOString();
  }

  wasActiveAt = (date) => {
    var dueDate = new Date(date)
    dueDate.setMonth(dueDate.getMonth()-3)
    return this.updatedAt > dueDate.toISOString();
  }

  hadAbo = () => {
    return this.isPremiumTill > new Date(2020,0,1).toISOString()
  }

  hadActiveAboAt = (date) => {
    const aboActive = this.isPremiumTill >= date.toISOString()
    return aboActive && this.wasActiveAt(date)
  }

  hadInactiveAboAt = (date) => {
    const aboExpired = this.isPremiumTill < date.toISOString()
    return this.hadAbo() && (aboExpired || !this.wasActiveAt(date))
  }

  hasUser = (id) => {
    return this.owner == id || this.admins.includes(id) || this.cashiers.includes(id) || this.readers.includes(id) || this.reporters.includes(id)
  }
}


export default Club