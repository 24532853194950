class User {

  constructor(user) {
    this.id = user.id
    this.createdAt = user.createdAt
    this.updatedAt = user.updatedAt
    this.email = user.email
    this.clubs = user.clubs
    this.ip = user.ip
    this.location = user.location
  }

  static sortByDate = (x,y) => {
    if (x.createdAt < y.createdAt) return 1
    if (x.createdAt > y.createdAt) return -1
    return 0
  } 

  toJson = () => {
    return {
      id: this.id,
      email: this.email,
      ip: this.ip,
      locoation: this.location
    }
  }

  getData = () => {
    return this.id + this.email + this.createdAt + this.updatedAt + this.ip + this.location
  }

  newsletterName = () => {
    return this.clubs.length == 1 ? this.clubs[0] : "Kneipensportler*in"
  }

  createdSince = (dueDate) => {
    let today = new Date()
    return dueDate.toISOString() < this.createdAt && this.createdAt < today.toISOString().slice(0,10);
  }

  createdBetween = (minDate, maxDate) => {
    return minDate.toISOString() < this.createdAt && this.createdAt < maxDate.toISOString();
  }

  isActive = () => {
    var dueDate = new Date()
    dueDate.setMonth(dueDate.getMonth()-3)
    return this.updatedAt > dueDate.toISOString();
  }

  wasActiveAt = (date) => {
    var dueDate = new Date(date)
    dueDate.setMonth(dueDate.getMonth()-3)
    return this.updatedAt > dueDate.toISOString();
  }
}

export default User