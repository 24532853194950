class Trend {

  constructor(trend,clubData,userData) {

    this.minDate = new Date(2000,0,1,4).toISOString()
    this.maxDate = trend.maxDate.toISOString()

    this.clubs = clubData.filter(obj => this.minDate < obj.createdAt && obj.createdAt <= this.maxDate)
    this.users = userData.filter(obj => this.minDate < obj.createdAt && obj.createdAt <= this.maxDate)
    
    this.totalUsers = this.users.length
    this.activeUsers = this.users.filter(obj => obj.wasActiveAt(trend.maxDate)).length

    this.totalClubs = this.clubs.length
    this.totalClubsIOS = this.clubs.filter(obj => obj.isIOS()).length
    this.totalClubsAndroid = this.clubs.filter(obj => obj.isAndroid()).length

    this.activeClubs = this.clubs.filter(obj => obj.wasActiveAt(trend.maxDate)).length
    this.activeClubsIOS = this.clubs.filter(obj => obj.wasActiveAt(trend.maxDate) && obj.isIOS()).length
    this.activeClubsAndroid = this.clubs.filter(obj => obj.wasActiveAt(trend.maxDate) && obj.isAndroid()).length
    
    this.totalConnectedClubs = this.clubs.filter(obj => obj.isConnected()).length
    this.totalConnectedClubsIOS = this.clubs.filter(obj => obj.isConnected() && obj.isIOS()).length
    this.totalConnectedClubsAndroid = this.clubs.filter(obj => obj.isConnected() && obj.isAndroid()).length
    
    this.activeConnectedClubs = this.clubs.filter(obj => obj.wasActiveAt(trend.maxDate) && obj.isConnected()).length
    this.activeConnectedClubsIOS = this.clubs.filter(obj => obj.wasActiveAt(trend.maxDate) && obj.isConnected() && obj.isIOS()).length
    this.activeConnectedClubsAndroid = this.clubs.filter(obj => obj.wasActiveAt(trend.maxDate) && obj.isConnected() && obj.isAndroid()).length
    
    this.totalAbos = this.clubs.filter(obj => obj.hadAbo()).length
    this.totalAbosIOS = this.clubs.filter(obj => obj.hadAbo() && obj.isIOS()).length
    this.totalAbosAndroid = this.clubs.filter(obj => obj.hadAbo() && obj.isAndroid()).length
    
    this.activeAbos = this.clubs.filter(obj => obj.hadActiveAboAt(trend.maxDate)).length
    this.activeAbosIOS = this.clubs.filter(obj => obj.hadActiveAboAt(trend.maxDate) && obj.isIOS()).length
    this.activeAbosAndroid = this.clubs.filter(obj => obj.hadActiveAboAt(trend.maxDate) && obj.isAndroid()).length

    this.inactiveAbos = this.clubs.filter(obj => obj.hadInactiveAboAt(trend.maxDate)).length
    this.inactiveAbosIOS = this.clubs.filter(obj => obj.hadInactiveAboAt(trend.maxDate) && obj.isIOS()).length
    this.inactiveAbosAndroid = this.clubs.filter(obj => obj.hadInactiveAboAt(trend.maxDate) && obj.isAndroid()).length
  }

  static sortByDate = (x,y) => {
    if (x.maxDate > y.maxDate) return 1
    if (x.maxDate < y.maxDate) return -1
    return 0
  } 
}

export default Trend